<template>
  <div class="login">
    <div class="login-wrap">
      <el-form ref="loginRefs" :model="form" :rules="rules">
        <div class="login-title">{{ title }}</div>
        <el-form-item prop="account">
          <el-input
            v-model="form.account"
            prefix-icon="el-icon-user"
            auto-complete="off"
            placeholder="请输入账号"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            type="password"
            prefix-icon="el-icon-lock"
            auto-complete="off"
            placeholder="请输入密码"
            show-password
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-input
            v-model="form.code"
            prefix-icon="el-icon-search"
            auto-complete="off"
            style="width: 63%"
            placeholder="请输入验证码"
          >
          </el-input>
          <div class="code">
            <img :src="captchaSrc" @click="getCaptcha" class="code-image" />
          </div>
        </el-form-item>

        <el-button
          type="primary"
          style="width: 100%"
          :loading="loading"
          @click="onLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { apiGetCaptcha } from "@/api/passport";

import config from "@/config";

export default {
  data() {
    return {
      captchaSrc: "",
      form: {
        account: "",
        password: "",
        code: "",
        hash: "",
      },
      rules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  computed: {
    title() {
      return config.title;
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      apiGetCaptcha().then((resp) => {
        this.captchaSrc = resp.base64;
        this.form.hash = resp.hash;
      });
    },
    onLogin() {
      this.$refs.loginRefs.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$store
          .dispatch("actionLogin", this.form)
          .then(() => {
            this.$router.replace({ path: this.redirect || "/" });
          })
          .catch(() => {
            this.getCaptcha();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  background-image: url("../../assets/images/login-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  .login-wrap {
    width: 400px;
    height: 320px;
    padding: 20px 0;
    box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    background: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    .login-title {
      font-size: 20px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .code {
    width: 33%;
    height: 30px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .code-image {
    width: 100%;
    height: 30px;
  }
}
</style>
